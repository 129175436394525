import { ref } from "vue";

import { defineStore } from "pinia";

export type AiContentBriefResponse = {
  thinking: boolean;
  socketStarted: boolean;
  done: boolean;
  response: string;
  threadId: string;
};

export type AiContentBriefResponses = {
  // We use object instead array because we want to use the threadId as key
  [key: string]: AiContentBriefResponse;
};

export type AiData = {
  thread_id: string;
  event_data: {
    event: AiEvent;
    data: {
      id: string;
      object: string;
      delta: {
        content: AiContent[];
      };
    };
  };
  ai_content_brief_id: string;
};

export type AiContent = {
  index: number;
  type: string;
  text: {
    value: string;
    annotations: unknown[];
  };
};

export enum AiEvent {
  // Thread Events
  ThreadCreated = "thread.created",

  // Run Events
  ThreadRunCreated = "thread.run.created",
  ThreadRunQueued = "thread.run.queued",
  ThreadRunInProgress = "thread.run.in_progress",
  ThreadRunCompleted = "thread.run.completed",

  // Run Step Events
  ThreadRunStepCreated = "thread.run.step.created",
  ThreadRunStepInProgress = "thread.run.step.in_progress",
  ThreadRunStepCompleted = "thread.run.step.completed",

  // Message Events
  ThreadMessageCreated = "thread.message.created",
  ThreadMessageInProgress = "thread.message.in_progress",
  ThreadMessageDelta = "thread.message.delta",
  ThreadMessageCompleted = "thread.message.completed",

  // Final Event
  Done = "done",
}

// These constants are ordered according as its flow
// const AiPrepareEvents = [
//   AiEvent.ThreadCreated,
//   AiEvent.ThreadRunCreated,
//   AiEvent.ThreadRunQueued,
//   AiEvent.ThreadRunInProgress,
//   AiEvent.ThreadRunCompleted,
//   AiEvent.ThreadRunStepCreated,
//   AiEvent.ThreadRunStepInProgress,
//   AiEvent.ThreadRunStepCompleted,
//   AiEvent.ThreadMessageCreated,
//   AiEvent.ThreadMessageInProgress,
// ];

export const useAiContentBriefStore = defineStore(
  "ai-content-brief-store",
  () => {
    const aiContentBriefResponses = ref<AiContentBriefResponses>({});

    const initAiContentBriefResponse = (
      aiContentBriefId: string,
      threadId: string
    ): void => {
      aiContentBriefResponses.value[aiContentBriefId] = {
        thinking: true,
        response: "",
        done: false,
        threadId: threadId,
        // We don't use this parameter at this moment.
        // But, maybe It will be useful in a close future
        socketStarted: true,
      };
    };

    const setThinking = (aiContentBriefId: string, status: boolean): void => {
      if (aiContentBriefResponses.value[aiContentBriefId])
        aiContentBriefResponses.value[aiContentBriefId].thinking = status;
    };

    const setDone = (aiContentBriefId: string, status: boolean): void => {
      aiContentBriefResponses.value[aiContentBriefId].done = status;
    };

    const updateContentBriefResponse = (
      aiContentBriefId: string,
      content: AiContent[]
    ): void => {
      const newText: string =
        content.map((item) => item.text.value).join("") ?? "";
      aiContentBriefResponses.value[aiContentBriefId].response += newText;
    };

    const clearAiContentBriefResponse = (aiContentBriefId: string): void => {
      aiContentBriefResponses.value[aiContentBriefId].response = "";
    };

    const deleteAiContentBriefResponse = (aiContentBriefId: string): void => {
      delete aiContentBriefResponses.value[aiContentBriefId];
    };

    const handleMessage = (aiData: AiData): void => {
      const { event, data } = aiData.event_data;
      const aiContentBriefId = aiData.ai_content_brief_id;

      if (event === AiEvent.ThreadCreated) {
        initAiContentBriefResponse(aiData.ai_content_brief_id, data.id);
      }

      // When the endpoint is canceled the websocket continue, so we need this conditional
      if (aiContentBriefResponses.value[aiContentBriefId]) {
        if (event === AiEvent.ThreadRunCreated) {
          setDone(aiContentBriefId, false);
          clearAiContentBriefResponse(aiContentBriefId);
          setThinking(aiContentBriefId, true);
        }

        if (event === AiEvent.ThreadMessageInProgress) {
          setThinking(aiContentBriefId, false);
        }

        if (event === AiEvent.ThreadMessageDelta) {
          updateContentBriefResponse(aiContentBriefId, data.delta.content);
        }

        if (event === AiEvent.Done) {
          setDone(aiContentBriefId, true);
        }
      }
    };

    return {
      aiContentBriefResponses,
      deleteAiContentBriefResponse,
      handleMessage,
    };
  }
);
