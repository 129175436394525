import { computed } from "vue";

import { MarketplaceCode } from "@/shared/enums/MarketplaceCodeEnum";

import { useMarketplace } from "@/shared/composables/Marketplace";

export const useIntegrationSeller = () => {
  const { isMarketplace } = useMarketplace();

  const urlTikTokShop =
    "https://services.tiktokshops.us/open/prefill_order?prefill_order_id=7470923152132032302";

  const idTikTokShopModal = "tiktok-shop-modal";
  const idIntegrationSuccessModal = "integration-success-modal";

  const isEnabledIntegrations = computed((): boolean => {
    return isMarketplace(MarketplaceCode.Us);
  });

  return {
    idTikTokShopModal,
    idIntegrationSuccessModal,
    urlTikTokShop,
    isEnabledIntegrations,
  };
};
