<template>
  <table class="table-auto w-full border-y border-gray-100 mb-6 text-sm">
    <tbody class="divide-y divide-gray-100" data-testid="wire-money-info-table">
      <!-- Bank account name -->
      <tr data-testid="bank-account-name">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.uk.label.bankAccountName"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="bank-account-name">
              {{
                t(
                  "modules.settings.wallet.wireMoneyModal.marketplaces.uk.info.bankAccountName"
                )
              }}
            </p>
            <DsButton
              theme-display="link"
              class="inline-flex"
              @click="copy('bank-account-name')"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>

      <!-- SWIFT -->
      <tr data-testid="wire-SWIFT">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.uk.label.swift"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="swift">
              {{
                t(
                  "modules.settings.wallet.wireMoneyModal.marketplaces.uk.info.swift"
                )
              }}
            </p>
            <DsButton
              theme-display="link"
              class="inline-flex"
              @click="copy('swift')"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>

      <!-- IBAN -->
      <tr data-testid="wire-IBAN">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.uk.label.iban"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="iban">
              {{
                t(
                  "modules.settings.wallet.wireMoneyModal.marketplaces.uk.info.iban"
                )
              }}
            </p>
            <DsButton
              theme-display="link"
              class="inline-flex"
              @click="copy('iban')"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>

      <!--Address -->
      <tr data-testid="wire-address">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.uk.label.address"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <address id="address" class="not-italic">
              <p>
                {{
                  t(
                    "modules.settings.wallet.wireMoneyModal.marketplaces.uk.info.address1"
                  )
                }}
              </p>
              <p>
                {{
                  t(
                    "modules.settings.wallet.wireMoneyModal.marketplaces.uk.info.address2"
                  )
                }}
              </p>
            </address>
            <DsButton
              theme-display="link"
              class="inline-flex"
              @click="copy('address')"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>

      <!--Reference -->
      <tr data-testid="wire-reference">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.uk.label.reference"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="reference" class="font-semibold">
              {{ reference }}
            </p>
            <DsButton
              theme-display="link"
              class="inline-flex"
              @click="copy('reference')"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { DsButton, DsCopyOutlineIcon, DsNotifier } from "@devsalsa/vue-core";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

import { useI18n } from "vue-i18n";

defineOptions({
  name: "UKWireInfo",
});

const { t } = useI18n();

const reference = AccountHandler.accountInfo.value?.wire_transfer_code || "";

async function copy(id: string): Promise<void> {
  const element = document.getElementById(id) as HTMLElement;
  await navigator.clipboard.writeText(element.innerText);
  DsNotifier.sendInfo({
    message: t("common.label.copied"),
    duration: 3,
  });
}
</script>
